import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./Chatbot.css";
import userPhotoURL1 from "./../assets/image/chat_bot_1.png";
import useApiRequest from "../hook/useApiRequest.js";
import Feedback from "./Feedback.js";
import useAuth from "../hook/useAuth.js";
import { v4 as uuidv4 } from "uuid";
import ChatbotButton from "./ChatBotButton.js";
import Flag from "react-world-flags";

const Chatbot = ({ setIsChatOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [lastMessage, setLastMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [firstMessageSent, setFirstMessageSent] = useState(false);
  const [feedbackData, setFeedbackData] = useState({});
  const [aiAnswer, setAiAnswer] = useState("");

  const agents = [{ userName: "Glauco Pereira", userPhoto: userPhotoURL1 }];
  const randomIndex = Math.floor(Math.random() * agents.length);
  const selectedAgent = agents[randomIndex];

  const { currentUser } = useAuth();

  const [conversationId, setConversationId] = useState(null); // Unique conversation ID
  const [selectedLanguages, setSelectedLanguages] = useState(["ms-MY"]);

  const {
    loading,
    error,
    response,
    chatPrompt,
    saveFeedback,
  } = useApiRequest();

  useEffect(() => {
    setIsChatOpen(isOpen);
  }, [isOpen, setIsChatOpen]);

  // Toggle the chatbot open or closed
  const toggleChatbot = () => {
    setIsOpen((prev) => !prev);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    console.log("Current Feedback Data:", feedbackData);
  }, [feedbackData]);

  // Send the first message when the chatbot is opened for the first time
  useEffect(() => {
    if (isOpen && !firstMessageSent) {
      setIsTyping(true); // Show typing animation

      const newConversationId = uuidv4(); // Generate a unique ID
      setConversationId(newConversationId);
      console.log("New Conversation ID:", newConversationId); // For debugging

      setTimeout(() => {
        const welcomeMessage = {
          text: `Hey ${capitalizeFirstLetter(
            currentUser.user
          )}, how can I help you?`,
          sender: "bot",
          conversationId,
          user: "BOTNAME",
        };

        setMessages([welcomeMessage]);
        setIsTyping(false);
        setFirstMessageSent(true); // Ensure the welcome message only appears once
      }, 100); // Delay to simulate typing
    }
  }, [isOpen, firstMessageSent]);

  const getTranslations = () => {
    if (!response) return [];
    return selectedLanguages.map((language) => {
      const translation = response.translations.find(
        (item) => item.language === language
      );
      return translation ? translation.text : response.generation; // Fallback
    });
  };

  useEffect(() => {
    if (response) {
      console.log("Response from API:", response);
      const translations = getTranslations();
      translations.forEach((text) => {
        triggerBotResponse(text);
      });
    }
  }, [response]);

  // Handle sending a message
  const handleSendMessage = async () => {
    if (message.trim()) {
      const newMessage = {
        text: message,
        sender: "user",
        conversationId,
        user: currentUser.user,
      };
      setLastMessage(message);
      await chatPrompt(message);
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setMessage(""); // Clear input
    }
  };

  // Simulate bot response with a typing animation
  const triggerBotResponse = (translatedText) => {
    setIsTyping(true); // Start typing animation

    setTimeout(() => {
      const botMessage = {
        text: translatedText,
        sender: "bot",
        conversationId,
      };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setIsTyping(false); // Stop typing animation
    }, 1500); // Delay response to simulate typing
  };

  // Handle language button toggle
  const handleLanguageButton = (language) => {
    setSelectedLanguages(
      (prev) =>
        prev.includes(language)
          ? prev.filter((lang) => lang !== language) // Remove if already selected
          : [...prev, language] // Add if not selected
    );
  };

  return (
    <div className="chatbot">
      <ChatbotButton toggleChatbot={toggleChatbot} />

      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="chatbot-window"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
          >
            <div className="chatbot-header">
              <img
                src={selectedAgent.userPhoto}
                alt={`${selectedAgent.userName}'s avatar`}
                className="chatbot-avatar"
              />
              <h4>{selectedAgent.userName}</h4>
              <button onClick={toggleChatbot} className="close-button">
                ✖️
              </button>
            </div>
            <div className="language-holder">
              <button
                onClick={() => handleLanguageButton("ms-MY")}
                className={
                  selectedLanguages.includes("ms-MY") ? "selected" : ""
                }
              >
                Bahasa
              </button>
              <button
                onClick={() => handleLanguageButton("cn")}
                className={selectedLanguages.includes("cn") ? "selected" : ""}
              >
                中文
              </button>
              <button
                onClick={() => handleLanguageButton("en")}
                className={selectedLanguages.includes("en") ? "selected" : ""}
              >
                English
              </button>
            </div>
            <div className="chatbot-body">
              {messages.map((msg, index) => (
                <>
                  {" "}
                  <div> </div>
                  <div
                    key={index}
                    className={`message-container ${msg.sender}`}
                  >
                    <p
                      className={`message ${msg.sender} ${
                        msg.feedback === "liked"
                          ? "liked"
                          : msg.feedback === "disliked"
                          ? "disliked"
                          : ""
                      }`}
                    >
                      {msg.text}
                    </p>
                    {msg.sender === "bot" && (
                      <Feedback
                        onLike={() => {
                          const updatedMessages = [...messages];
                          updatedMessages[index] = {
                            ...msg,
                            feedback: "liked",
                          };
                          setMessages(updatedMessages);
                        }}
                        onDislike={() => {
                          const updatedMessages = [...messages];
                          updatedMessages[index] = {
                            ...msg,
                            feedback: "disliked",
                          };
                          setMessages(updatedMessages);
                        }}
                        onClearThumbs={() => {
                          const updatedMessages = [...messages];
                          updatedMessages[index] = { ...msg, feedback: "" };
                          setMessages(updatedMessages);
                        }}
                        lastMessage={lastMessage}
                        aiAnswer={aiAnswer}
                        language={selectedLanguages}
                      />
                    )}
                  </div>
                </>
              ))}
              {isTyping && (
                <p
                  className={`message bot typing ${
                    messages.length > 0 &&
                    messages[messages.length - 1].feedback === "disliked"
                      ? "disliked"
                      : ""
                  }`}
                >
                  "Typing..."
                </p>
              )}
            </div>

            <div className="chatbot-input">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={"Type your message..."}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSendMessage();
                  }
                }}
              />
              {loading ? (
                <span className="chatbot-sending">Sending…</span>
              ) : (
                <button onClick={handleSendMessage}>Send</button>
              )}
            </div>
            <div className="chatbot-body">
              {messages.map((msg, index) => (
                <div key={index} className={`message-container ${msg.sender}`}>
                  {Array.isArray(msg.text) ? (
                    msg.text.map((translation, i) => (
                      <p key={i} className={`message ${msg.sender}`}>
                        {translation}
                      </p>
                    ))
                  ) : (
                    <p className={`message ${msg.sender}`}>{msg.text}</p>
                  )}
                  {msg.sender === "bot" && (
                    <Feedback
                      onLike={() => {
                        const updatedMessages = [...messages];
                        updatedMessages[index] = { ...msg, feedback: "liked" };
                        setMessages(updatedMessages);
                      }}
                      onDislike={() => {
                        const updatedMessages = [...messages];
                        updatedMessages[index] = {
                          ...msg,
                          feedback: "disliked",
                        };
                        setMessages(updatedMessages);
                      }}
                      onClearThumbs={() => {
                        const updatedMessages = [...messages];
                        updatedMessages[index] = { ...msg, feedback: "" };
                        setMessages(updatedMessages);
                      }}
                      lastMessage={lastMessage}
                      aiAnswer={aiAnswer}
                      language={selectedLanguages}
                    />
                  )}
                </div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Chatbot;
