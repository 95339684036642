import { useState, useEffect } from 'react';

const useAuth = () => {
  const [logged, setLogged] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const usersData = [
    { userId: '1', user: 'glauco', pass: '1' },
    { userId: '2', user: 'leo', pass: '1' },
    { userId: '3', user: 'jared', pass: '1' },
    { userId: '4', user: 'max', pass: '1' },
  ];

  useEffect(() => {
    const cachedUser = localStorage.getItem('user');
    if (cachedUser) {
      setLogged(true);
      setCurrentUser(JSON.parse(cachedUser));
    }
  }, []);

  const login = (username, password) => {
    const user = usersData.find(
      (u) => u.user === username && u.pass === password,
    );

    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
      setLogged(true);
      setCurrentUser(user);
      return true;
    } else {
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('user');
    setLogged(false);
    setCurrentUser(null);
  };

  return { logged, login, logout, currentUser };
};
export default useAuth;
