import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "axios";
import AnswerProgress from "../components/AnswerProgress.js";
import Flag from "react-world-flags";
import IconJoker from "../assets/icon/IconJoker.js";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(360deg, #494231, #0e0b03);
  font-family: "Roboto", sans-serif;
  color: #333;
  padding: 20px;
  box-sizing: border-box;
  @media screen and (max-width: 375px), screen and (max-height: 800px) {
    align-items: flex-start;
  }
`;

const Card = styled(motion.div)`
  background: linear-gradient(180deg, #212121, #37342b);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 50px 30px 80px #25190580, -20px -20px 60px #0f080024;
  max-width: 40rem;
  min-width: 40vw;
  text-align: center;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ffc23a1c;
  width: 100%;
  height: auto;
  margin-bottom: 40px;
  flex-grow: 1; /* Make the Card take up the remaining space */

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
  @media screen and (max-width: 375px), screen and (max-height: 800px) {
    min-width: auto;
  }
`;

const Button = styled(motion.button)`
  background: var(
    --jokerColors-jokerGradients-jokerGradientGold02,
    linear-gradient(345deg, #d6bc9e 3.8%, #ffeec0 50.91%, #d6bc9e 98.51%)
  );
  color: green;
  color: black;
  padding: 12px 30px;
  border: 2px solid #bba07217;

  border-style: inset;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 16px;
  box-shadow: 0px 5px 14px #110d0078;
  width: clamp(15rem, 5.178vw + 13.786rem, 20rem);
  transition: background-color 0.3s ease;
  user-select: none;

  &:hover {
    background-color: red;
  }
`;

const SkipButton = styled(motion.button)`
  background-color: transparent;
  color: #a6a6a6;
  text-decoration: underline;
  padding: 12px 30px;
  margin-bottom: 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 16px;

  user-select: none;

  &:hover {
  }
`;

const Textarea = styled(motion.textarea)`
  padding: 18px;
  width: 98%;
  margin-top: 20px;
  border-radius: 1rem;
  border: 1px solid #030303;
  font-size: 16px;
  transition: border 0.3s ease;
  resize: none;
  height: 10rem;
  color: #fff;
  background: #212121;
  box-shadow: inset 0px 8px 2px #0000001f;

  &:focus {
    outline: none;
    border: 1px solid #bba072;
  }
`;

const Spinner = styled(motion.div)`
  font-size: 2rem;
  color: #d6bc9e;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ThankYouMessage = styled(motion.div)`
  margin-top: 20px;
  font-size: 18px;
  color: #8be99b;
  font-weight: bold;
  padding: 0.5rem 1rem;
  min-width: 20rem;
  border-radius: 10px;
`;

const CollectAnswer = () => {
  const [question, setQuestion] = useState(null);
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  useEffect(() => {
    const fetchQuestion = async () => {
      try {
        const response = await axios.get("https://glauco.website/question.php");

        if (response.data.success) {
          const {
            question_id, // Pegue o question_id da resposta
            question,
            question_my,
            question_zh_tw,
          } = response.data.question;

          setQuestion({
            question_id: question_id, // Salve o question_id
            original: question,
            malay: question_my || question, // Default para o original se não estiver disponível
            chinese: question_zh_tw || question, // Default para o original se não estiver disponível
          });
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error("Failed to fetch question", error);
      }
    };

    fetchQuestion();
  }, []);

  const handleQuestion = async () => {
    if (!answer || !question || !question.question_id) {
      alert("Please provide an answer before submitting.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post("https://glauco.website/question.php", {
        answer: answer, // Enviando a resposta
        question_id: question.question_id, // Agora o question_id é enviado corretamente
      });

      if (response.data.success) {
        setAnswer(""); // Limpa o campo de resposta
        setShowThankYou(true); // Mostra a mensagem de agradecimento

        setTimeout(() => {
          setShowThankYou(false);
          window.location.reload(); // Recarrega a página para buscar uma nova pergunta
        }, 2000);
      } else {
        console.error("Failed to submit the answer:", response.data.message);
        alert("Failed to submit the answer.");
      }
    } catch (error) {
      console.error("Error submitting the answer:", error);
      alert("There was an error submitting the answer.");
    } finally {
      setLoading(false);
    }
  };

  const handleSkip = () => {
    window.location.reload();
  };

  return (
    <Container>
      <Card>
        <h1
          style={{
            color: "#AE2B90",
            backgroundColor: "#FECBFE",
            padding: "0.5rem 1.5rem 0.65rem 1.5rem",
            borderRadius: "8px",
            border: "2px solid #D78CD5",
            fontSize: "1rem",
          }}
        >
          Stage 1 - Data Gathering
        </h1>

        <div
          style={{
            color: "white",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            fontSize: "1.3rem",
            textAlign: "center",
            marginBottom: "20px",
            lineHeight: "1.6",
          }}
        >
          {<IconJoker />}
          {question ? (
            <>
              <div>{question.original}</div>

              <div
                style={{
                  color: "#A6A6A6",
                  marginTop: "2rem",
                }}
              >
                <Flag height="16" code={458} />
                {" " + question.malay}
              </div>
              {/* Chinese Translation with margin-top and color styling */}
              <div
                style={{
                  color: "#A6A6A6",
                  marginTop: "0.5rem",
                }}
              >
                <Flag height="16" code={156} />
                {" " + question.chinese}
              </div>
            </>
          ) : (
            "Loading question..."
          )}
        </div>

        <Textarea
          placeholder="Your answer here..."
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
        />

        {showThankYou && (
          <ThankYouMessage>Thank you for your feedback!</ThankYouMessage>
        )}

        <Button onClick={handleQuestion} disabled={loading}>
          Submit
        </Button>

        <SkipButton onClick={handleSkip}>Skip Question</SkipButton>

        {loading && (
          <Spinner>
            <AiOutlineLoading3Quarters />
          </Spinner>
        )}
      </Card>
      <AnswerProgress />
    </Container>
  );
};

export default CollectAnswer;
