import React, { useState } from 'react';
import Rating from './Rating.js';
import './Feedback.css';
import ThankYouScreen from './ThankYouScreen.js';

const Feedback = ({
  onLike,
  onDislike,
  onClearThumbs,
  lastMessage,
  aiAnswer,
  language,
}) => {
  // State to track if the "like" button was clicked
  const [isLiked, setIsLiked] = useState(false);
  // State to store the rating value
  const [isDisliked, setIsDisliked] = useState(false);
  const [rating, setRating] = useState(undefined);
  // State to control whether the confirm button is displayed
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [jsonResponse, setJsonResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  // Handles "like" button click
  const handleLike = () => {
    if (isLiked) {
      setIsLiked(false);
      onClearThumbs();
    } else {
      setIsLiked(true); // Sets the "liked" state
      setIsDisliked(false);
      onLike(); // Calls the onLike function passed as a prop
      setCorrectAnswer('');
    }
  };

  const handleDislike = () => {
    if (isDisliked) {
      setIsDisliked(false);
      onClearThumbs();
    } else {
      setIsDisliked(true);
      setIsLiked(false);
      onDislike();
      setRating(0);
      setShowConfirmButton(false);
      setCorrectAnswer('');
    }
  };

  // Handles changes in the rating value
  const handleRatingChange = (newRating) => {
    console.log('New Rating:', newRating); // Logs the new rating for debugging
    setRating(newRating); // Updates the rating state
    if (newRating <= 3) {
      setShowConfirmButton(false);
    } else {
      setShowConfirmButton(true);
      setCorrectAnswer('');
    }
  };

  const handleRightAnswer = (text) => {
    setCorrectAnswer(text);
    if (text.length > 0) {
      setShowConfirmButton(true);
    } else {
      setShowConfirmButton(false);
    }
  };

  const handleSubmitServer = async () => {
    setLoading(true); // Set loading state to true

    setTimeout(() => {
      setLoading(false); // Set loading state to false
    }, 3000); // Simulate a 3-second delay

    // Create the feedbackData object with necessary data
    const feedbackData = {
      user_input: lastMessage,
      ai_response: aiAnswer,
      correct_bool: isLiked ? true : isDisliked ? false : null,
      Correct_Answer: correctAnswer || null, // Only add if disliked
      chat_rating: rating,
      Metadata: { Category: 'Account', Frequency: '', Difficulty: '' },
    };

    // Update jsonResponse state to show the feedback data
    //setJsonResponse(feedbackData);
    //console.log("Feedback Data:", feedbackData);

    // Attempt to save the feedback and capture the result
    //const result = await saveFeedback(feedbackData);

    //if (result.success) {
    // If save is successful, show thank-you message and start countdown
    //setShowThankYou(true);
    //setCountdown(3);

    // Reset the form after a brief delay
    // setTimeout(() => {
    //resetForm();
    // }, 100);
    //} else {
    // If there's an error, update feedbackError with the message
    //setFeedbackError(
    //result.error || "Failed to submit feedback. Please try again."
    //);
    // }
  };

  return (
    <div className="feedback">
      <div className="feedback-buttons">
        {/* Like button with conditional 'liked' class */}
        <button
          onClick={handleLike}
          className={`thumb ${isLiked ? 'liked' : ''}`}
        >
          👍
        </button>
        {/* Dislike button */}
        <button
          onClick={handleDislike}
          className={`thumb ${isDisliked ? 'disliked' : ''}`}
        >
          👎
        </button>
      </div>

      {isLiked && (
        <div className="rating">
          {/* Rating component with a maximum rating of 6 */}
          <Rating
            maxRating={6}
            initialRating={rating}
            onRatingChange={handleRatingChange}
          />
        </div>
      )}

      {(isDisliked || rating <= 3) && (
        <div className="rating-text">
          <textarea
            rows="6"
            type="text"
            value={correctAnswer}
            onChange={(e) => handleRightAnswer(e.target.value)}
            placeholder={'Add correct answer...'}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSendMessage();
              }
            }}
          />
        </div>
      )}

      {showConfirmButton && (
        <button
          onClick={handleSubmitServer}
          className="confirm-button"
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Confirm'}
        </button>
      )}

      {<ThankYouScreen language={language} />}
    </div>
  );
};

export default Feedback;
