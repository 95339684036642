import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import useApiRequest from "../hook/useApiRequest.js";
import Typewriter from "typewriter-effect";
import Rating from "../components/Rating.js";

import LanguageDetect from "languagedetect";
import IconJoker from "../assets/icon/IconJoker.js";

const lngDetector = new LanguageDetect();

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background: linear-gradient(360deg, #494231, #0e0b03);
  font-family: "Roboto", sans-serif;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  @media screen and (max-width: 375px), screen and (max-height: 800px) {
    align-items: flex-start;
  }
`;

const Card = styled(motion.div)`
  background: linear-gradient(180deg, #212121, #37342b);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 50px 30px 80px #25190580, -20px -20px 60px #0f080024;
  max-width: 40rem;
  min-width: 40vw;
  text-align: center;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ffc23a1c;
  @media screen and (max-width: 375px), screen and (max-height: 800px) {
    min-width: auto;
  }
`;

const Button = styled(motion.button)`
  background: var(
    --jokerColors-jokerGradients-jokerGradientGold02,
    linear-gradient(345deg, #d6bc9e 3.8%, #ffeec0 50.91%, #d6bc9e 98.51%)
  );
  color: green;
  color: black;
  padding: 12px 30px;
  border: 2px solid #bba07217;

  border-style: inset;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 16px;
  box-shadow: 0px 5px 14px #110d0078;
  min-width: 20rem;
  transition: background-color 0.3s ease;
  user-select: none;

  &:hover {
    background-color: red;
  }
`;

const Textarea = styled(motion.textarea)`
  padding: 18px;
  width: 98%;
  margin-top: 20px;
  border-radius: 1rem;
  border: 1px solid #030303;
  font-size: 16px;
  transition: border 0.3s ease;
  resize: none;
  height: 10rem;
  color: #fff;
  background: #212121;
  box-shadow: inset 0px 8px 2px #0000001f;

  &:focus {
    outline: none;
    border: 1px solid #bba072;
  }
`;

const JsonDisplay = styled(motion.div)`
  background: #212121;
  padding: 20px;
  border-radius: 15px;
  margin-top: 25px;
  text-align: left;
  color: #3edb58;
  filter: drop-shadow(1px 1px 10px #3edb5840);
  border: 1px dashed #3edb58;
  font-family: "Courier New", Courier, monospace;
  font-size: 1rem;
  line-height: 170%;
  white-space: pre-wrap;
  width: 90%;
  margin-top: 3rem;
`;

const LikeDislikeContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
`;

const IconButtonRed = styled(motion.button)`
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: ${(props) => (props.active ? "#DB3E3E" : "#4E4B3E")};
  transition: color 0.3s ease;
  filter: drop-shadow(
    1px 1px 10px ${(props) => (props.active ? "#db3e3e52" : "transparent")}
  );

  &:hover {
    color: #fdebbe;
  }
`;

const IconButtonGreen = styled(motion.button)`
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: ${(props) => (props.active ? "#3EDB58" : "#4E4B3E")};
  transition: color 0.3s ease;
  filter: drop-shadow(
    1px 1px 10px ${(props) => (props.active ? "#3edb5870" : "transparent")}
  );

  &:hover {
    color: #fdebbe;
  }
`;

const AIResponse = styled(motion.div)`
  background: ${(props) => {
    let backgroundColor;
    if (props.like) {
      backgroundColor = "rgba(13, 61, 21, 1)";
    } else if (props.dislike) {
      backgroundColor = "#551414";
    } else {
      backgroundColor = "#262626";
    }
    return backgroundColor;
  }};
  padding: 18px;
  width: 93%;
  margin-top: 20px;
  border-radius: 20px;
  border: 1px solid #ffffff29;
  font-size: 16px;

  padding: 2rem;
  border-radius: 10px;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  line-height: 160%;
  font-weight: normal;
  color: white;
  border: 1px solid #278493;

  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
`;

const Spinner = styled(motion.div)`
  font-size: 3rem;
  color: #ffdea8;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const ThankYouMessage = styled(motion.div)`
  margin-top: 20px;
  font-size: 20px;
  color: #8be99b;
  font-weight: bold;
  padding: 0.5rem 1rem;
  min-width: 20rem;
  border-radius: 10px;
`;

const AskAI = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [correctedAnswer, setCorrectedAnswer] = useState("");
  const [jsonResponse, setJsonResponse] = useState(null);
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [feedbackError, setFeedbackError] = useState(null);
  const [countdown, setCountdown] = useState(3);

  const { loading, response, chatPrompt, saveFeedback } = useApiRequest();
  const [rating, setRating] = useState(0);

  const handleRatingChange = (newRating) => {
    console.log("New Rating:", newRating);
    setRating(newRating);
  };

  useEffect(() => {
    if (showThankYou) {
      // Only start the countdown if showThankYou is true
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(interval);
            setShowThankYou(false);
            window.location.reload(); // Reload the page when countdown reaches zero
            return prevCountdown;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      // Clean up the interval when component unmounts or showThankYou changes
      return () => clearInterval(interval);
    }
  }, [showThankYou]);

  // Use useEffect to set the answer when the response changes
  useEffect(() => {
    if (response) {
      const malaysianTranslation = response.translations.find(
        (translation) => translation.language === "ms"
      );
      console.log("Malaysian Translation:", malaysianTranslation.text);
      console.log("Response from API:", response.generation);
      setAnswer(malaysianTranslation.text);
    }
  }, [response]); // Runs whenever response changes

  const handleQuestion = async () => {
    setAnswer("");
    setJsonResponse(null);
    setLike(false);
    setDislike(false);
    setCorrectedAnswer("");
    console.log("Question Language:", lngDetector.detect(question, 1));

    // Call the chatPrompt (which is async) and the answer will be set in useEffect
    await chatPrompt(question);
  };

  const handleSubmit = async () => {
    const feedbackData = {
      user_input: question,
      ai_response: answer,
      correct_bool: like,
      Correct_Answer: correctedAnswer || null,
      chat_rating: rating,
      Metadata: { Category: "Account", Frequency: "", Difficulty: "" },
    };

    setJsonResponse(feedbackData);

    const result = await saveFeedback(feedbackData);

    if (result.success) {
      setShowThankYou(true);
      setCountdown(3);

      setTimeout(() => {
        resetForm();
      }, 100);
    } else {
      setFeedbackError(
        result.error || "Failed to submit feedback. Please try again."
      );
    }
  };

  const resetForm = () => {
    setQuestion("");
    setAnswer("");
    setCorrectedAnswer("");
    setLike(false);
    setDislike(false);
    setRating(0);
    setJsonResponse(null);
    setTimeout(() => {
      setShowThankYou(false);
      window.location.reload();
    }, 3000);
  };

  const handleLike = () => {
    setLike(true);
    setDislike(false);
  };

  const handleDislike = () => {
    setLike(false);
    setDislike(true);
    setRating(0);
  };

  return (
    <Container>
      <Card
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
      >
        <h1
          style={{
            color: "#478570",
            backgroundColor: "#CBFED3",
            padding: "0.5rem 1.5rem 0.65rem 1.5rem",
            borderRadius: "8px",
            border: "2px solid #ACD7B2",
            fontSize: "1rem",
          }}
        >
          Stage 2 - Quality Control
        </h1>

        <IconJoker />
        <h2 style={{ color: "#FFDEA8" }}>Ask the AI</h2>
        <Textarea
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          placeholder="Write your question here"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleQuestion();
            }
          }}
        />
        <Button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleQuestion}
        >
          Ask
        </Button>

        {loading && !showThankYou && (
          <Spinner>
            <AiOutlineLoading3Quarters />
          </Spinner>
        )}

        {answer && !loading && !showThankYou && (
          <>
            <AIResponse
              like={like ? "true" : undefined}
              dislike={dislike ? "true" : undefined}
            >
              <p style={{ fontWeight: "bold" }}>🤖 Ai Response: 🤖</p>

              <Typewriter
                options={{
                  delay: 20,
                }}
                onInit={(typewriter) => {
                  typewriter.typeString(answer).start();
                }}
              />
            </AIResponse>

            <LikeDislikeContainer>
              <IconButtonGreen
                active={like ? "true" : undefined}
                onClick={handleLike}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              >
                <FaThumbsUp />
              </IconButtonGreen>
              <IconButtonRed
                active={dislike ? "true" : undefined}
                onClick={handleDislike}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              >
                <FaThumbsDown />
              </IconButtonRed>
            </LikeDislikeContainer>

            {dislike && (
              <Textarea
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                placeholder="Provide the correct answer"
                value={correctedAnswer}
                onChange={(e) => setCorrectedAnswer(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
              />
            )}

            {answer && like && (
              <Rating
                maxRating={6}
                initialRating={rating}
                onRatingChange={handleRatingChange}
              />
            )}

            <Button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleSubmit}
            >
              Confirm Answer
            </Button>
          </>
        )}

        {jsonResponse && !loading && !showThankYou && (
          <JsonDisplay
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <h4>JSON SAVED:</h4>
            <pre className="jsonWrapper">
              {JSON.stringify(jsonResponse, null, 2)}
            </pre>
          </JsonDisplay>
        )}

        {showThankYou && (
          <ThankYouMessage>
            Thank you for your feedback! <br />
            Page refreshing in {countdown}...
          </ThankYouMessage>
        )}
      </Card>
    </Container>
  );
};

export default AskAI;
