const IconJoker = () => {
  return (
    <div>
      <svg
        width="36"
        height="35"
        viewBox="0 0 36 35"
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
      >
        <path
          d="M18.3828 21.207L18.5886 21.1694C22.2322 20.5069 25.369 19.4053 27.9161 17.8996L27.2837 11.7742C26.3226 11.9724 25.3063 12.0703 24.2573 12.0703C22.2649 12.0703 20.8571 11.7139 20.7994 11.6989L20.433 11.6035L20.6614 11.3024C20.7567 11.1744 23.0378 8.21581 26.7642 6.7453L26.6639 5.78168L25.4594 5.77415L29.3188 0L29.1733 6.06525C30.0365 5.90966 30.9424 5.83187 31.8734 5.83187C33.8659 5.83187 35.2737 6.18821 35.3339 6.20326L35.7003 6.29611L35.4719 6.59724C35.369 6.73024 32.9625 9.85446 29.0503 11.2748L28.9073 17.2673C32.7341 14.675 33.6551 11.9423 33.6651 11.9147L34.1545 12.0201L33.3991 18.8683L18.3853 34.5696V21.202L18.3828 21.207Z"
          fill="url(#paint0_linear_9184_1347)"
        />
        <path
          d="M28.8154 11.0958C32.7954 9.71313 35.2772 6.44588 35.2772 6.44588C35.2772 6.44588 32.301 5.68051 28.9208 6.3706L29.0513 0.857422L25.9296 5.52744L26.8932 5.53245L27.0363 6.91012C23.2245 8.3455 20.8631 11.4547 20.8631 11.4547C20.8631 11.4547 24.0074 12.2627 27.5055 11.4672L28.1831 18.0318C25.9346 19.3844 22.8481 20.6466 18.6348 21.4145V33.9465L33.1592 18.7596L33.907 11.9967C33.907 11.9967 32.9334 15.0005 28.6498 17.7458L28.8079 11.1008C28.8079 11.1008 28.8129 11.1008 28.8154 11.1008V11.0958Z"
          fill="url(#paint1_linear_9184_1347)"
        />
        <path
          d="M2.85289 18.8702L2.09755 12.022L2.58689 11.9166C2.59693 11.9442 3.53043 14.687 7.34473 17.2692L7.2017 11.2767C3.282 9.84888 0.883 6.73219 0.780114 6.5992L0.551758 6.29807L0.918132 6.20522C0.975849 6.19016 2.38363 5.83382 4.37861 5.83382C5.3096 5.83382 6.2155 5.91162 7.07874 6.0672L6.93319 0.00195312L10.7952 5.77611L9.59066 5.78364L9.49028 6.74725C13.2168 8.21776 15.4978 11.1764 15.5932 11.3043L15.8215 11.6055L15.4552 11.7008C15.3949 11.7159 13.9897 12.0722 11.9972 12.0722C10.9482 12.0722 9.93194 11.9718 8.97083 11.7761L8.33846 17.9016C10.883 19.4072 14.0223 20.5063 17.6659 21.1713L17.8717 21.209V34.5766L2.85791 18.8752L2.85289 18.8702Z"
          fill="url(#paint2_linear_9184_1347)"
        />
        <path
          d="M7.44025 11.0958C3.46032 9.71313 0.978516 6.44588 0.978516 6.44588C0.978516 6.44588 3.95468 5.68051 7.33485 6.3706L7.20436 0.857422L10.3261 5.52744L9.36246 5.53245L9.21942 6.91012C13.0312 8.3455 15.3926 11.4547 15.3926 11.4547C15.3926 11.4547 12.2483 12.2627 8.75016 11.4672L8.07262 18.0318C10.3211 19.3844 13.4076 20.6466 17.6209 21.4145V33.9465L3.09646 18.7596L2.34865 11.9967C2.34865 11.9967 3.32231 15.0005 7.60587 17.7458L7.44778 11.1008C7.44778 11.1008 7.44276 11.1008 7.44025 11.1008V11.0958Z"
          fill="url(#paint3_linear_9184_1347)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_9184_1347"
            x1="17.8292"
            y1="35.9609"
            x2="35.9674"
            y2="0.642726"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0833333" stopColor="#F9DE95" />
            <stop offset="0.222655" stopColor="#8B7C52" />
            <stop offset="0.846374" stopColor="#63552E" />
            <stop offset="0.911458" stopColor="#F9DE95" />
            <stop offset="0.994792" stopColor="#8B7C52" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_9184_1347"
            x1="37.1154"
            y1="27.8373"
            x2="14.5287"
            y2="28.3089"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0833333" stopColor="#F9DE95" />
            <stop offset="0.222655" stopColor="#8B7C52" />
            <stop offset="0.846374" stopColor="#63552E" />
            <stop offset="0.911458" stopColor="#F9DE95" />
            <stop offset="0.994792" stopColor="#8B7C52" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_9184_1347"
            x1="10.3361"
            y1="3.2594"
            x2="10.4837"
            y2="30.9289"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D6BC9E" />
            <stop offset="0.494792" stopColor="#FFEEC0" />
            <stop offset="0.994792" stopColor="#D6BC9E" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_9184_1347"
            x1="17.673"
            y1="25.8983"
            x2="2.68508"
            y2="17.4086"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D6BC9E" />
            <stop offset="0.494792" stopColor="#FFEEC0" />
            <stop offset="0.994792" stopColor="#D6BC9E" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default IconJoker;
