import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// Styled Components for the progress bar and container
const BottomContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #212121;
  padding: 10px 10px 10px 10px;
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  flex-direction: column;
`;

const CompactProgressBarContainer = styled.div`
  width: 80%;
  background-color: #3d3d3d;
  border-radius: 12px;
  margin: 5px 0;
  box-shadow:
    inset 0px 1px 7px #2e2e2e,
    0px 1px 0px #c3c3c35c;
`;

const CompactProgressBar = styled.div`
  width: ${(props) => props.width}%;
  background-color: #d6bc9e;
  height: 10px;
  border-radius: 12px;
`;

const CompactPercentageText = styled.span`
  font-size: 1.14rem;
  color: #a6a6a6;
`;

const AnswerProgress = () => {
  const [totalAnswers, setTotalAnswers] = useState(0); // Number of answers from the database
  const [totalQuestions, setTotalQuestions] = useState(0); // Number of questions from the database
  const [progress, setProgress] = useState(0); // Percentage progress

  useEffect(() => {
    const fetchProgressData = async () => {
      try {
        // Fetch the number of questions and answers from the database
        const response = await axios.get(
          'https://glauco.website/question.php?progress=true',
        );

        const numQuestions = response.data.total_questions || 0;
        const numAnswers = response.data.total_answers || 0;

        setTotalQuestions(numQuestions);
        setTotalAnswers(numAnswers);

        // Calculate the total possible answers (5 answers per question)
        const totalPossibleAnswers = numQuestions * 5;

        // Calculate progress percentage
        const completionPercentage = totalPossibleAnswers
          ? (numAnswers / totalPossibleAnswers) * 100
          : 0;

        setProgress(completionPercentage.toFixed(2));
      } catch (error) {
        console.error('Error fetching progress data', error);
      }
    };

    fetchProgressData();
  }, []);

  return (
    <BottomContainer>
      {/* Display the total answers and total possible answers */}
      <CompactPercentageText>{`${totalAnswers} of ${totalQuestions * 5} answers completed`}</CompactPercentageText>

      {/* Display the progress bar */}
      <CompactProgressBarContainer>
        <CompactProgressBar width={progress} />
      </CompactProgressBarContainer>

      {/* Display the percentage */}
      <CompactPercentageText>{`${progress}%`}</CompactPercentageText>
    </BottomContainer>
  );
};

export default AnswerProgress;
